import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class About extends React.Component {
  render() {
    var styles = {
      float: 'right', 
      padding: '15px 20px', 
      width: '430px', 
      maxWidth: '100%',
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="About Us | Lewis Mohr Insurance Agency"
          meta={[
            {
              name: "description",
              content:
                "Since 1968, Lewis Mohr Insurance Agency has been providing insurance and risk management services to clients in Baton Rouge and all of Louisiana. We are founded on the premise of superior customer service along with quality risk management solutions."
            }
          ]}
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'top'}}  alt="Jeff and Brittany Mohr"/>
        <div className="site-body page-content">
          <h1>About Us</h1>
          <p>
            Lewis Mohr Insurance Agency provides insurance and risk management
            services to clients in Baton Rouge, Louisiana and across the
            country. We were founded in 1968 on the premise of superior customer
            service along with quality risk management solutions for our
            clients. As an Independent Insurance agent, we work only with
            carefully chosen insurance companies that have high financial and
            ethical standards that have withstood the test over time.
          </p>
          <div className="servicesVideoWrapper"><div className="videoWrapper"><iframe className="iframe services-video" width="560" height="315" src="https://www.youtube.com/embed/2XfvwzzTXb0?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Business Interruption Insurance: What You Need to Know" /></div></div>
          <h1>Our History</h1>
          <h3>A Company of Generations</h3>
          <p>
            Lewis Mohr was determined to achieve the American dream of owning
            his own business. He wanted an opportunity to help others and base
            his work on forming strong relationships while providing a service
            that was important to people.
          </p>
          <p>
            So Lewis left his job in corporate America as a banker and founded
            Lewis Mohr Real Estate & Insurance Agency in 1968, taking the first
            steps to make his dream a reality.
          </p>
          <div style={styles}>
          <Img
            title="Jeff and Lewis Mohr"
            alt="Two men stading shaking hands"
            fluid={this.props.data.lewisMohrJeffMohr.childImageSharp.fluid}            
          />
          <small className={styles.small}>Picture: Jeff Mohr (left) & Lewis Mohr (right)</small>
          </div>
          <p>
            In April of 1984, after building his company from the ground up,
            Lewis was unexpectedly diagnosed with lung cancer. Just two months
            later, Lewis passed away, leaving his youngest son Jeff to inherit
            the family business at 23 years old.
          </p>
          <p>
            Jeff Mohr originally began working for the family agency during high
            school and continued while earning his degree in Business
            Administration from LSU in Baton Rouge. It was no secret growing up
            that Jeff wanted to go to work for his father; he valued being able
            to form personal relationships through business and displayed an
            interest in helping people through the difficult times in their
            life.
          </p>
          <p>
            It was Jeff’s ability to look into the future and envision a path to
            growth that proved to be his strong suit. After his father’s
            passing, he immediately diversified, causing the company to triple
            in size. With the addition of his wife Lisa, their efforts have
            advanced the agency to where it is today.
          </p>
          <p>
            With the belief that for a company to succeed, it must invest in its
            employees, Jeff has put a continual focus on mentoring and providing
            his employees with an environment where they can grow in their
            field. In an age of constant mergers and acquisitions, Lewis Mohr
            remains family owned. Jeff's intention is to pass the company down
            to the younger generation giving them the opportunity to grow the
            business. Today, Lewis Mohr Real Estate & Insurance Agency grows
            into its third generation with the addition of Jeff’s daughter,
            Brittany, who is eager to follow in her father and grandfather's
            footsteps.
          </p>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "jeff-and-brittany-mohr-about-bg.jpg" }) {
      ...mainBg
    }
    lewisMohrJeffMohr: file(relativePath: { eq: "jeff-mohr-and-lewis-mohr-story.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-12.jpg" }) {
      ...mainBg
    }
  }
`;
