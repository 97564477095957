import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby"
import styles from "./callToActionGeneric.module.css";

class CallToActionGeneric extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Img
          title="Lewis Mohr Insurance Agency Contact Us Background"
          alt="Call to Action Background Image"
          fluid={this.props.bg.childImageSharp.fluid}
          className={styles.bg}
        />
        <div className="grid grid-justifyCenter">
          <div className="grid-cell u-med-3of4">
            <div className={styles.content}>
              <h3>Get in touch for an insurance quote</h3>
              <h6 className={styles.subtitle}>Our Baton Rouge based insurance agents are ready to help you find the right coverage.</h6>
              <Link className="button button-secondary" to={"/get-insurance/"}>Contact Form</Link>
              <a className="button button-secondary" href="tel:2252931086">225-293-1086</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToActionGeneric;